import Vue from 'vue'
// eslint-disable-next-line import/no-unresolved
import { $backEndUrl } from '@serverConfig'
// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: `${$backEndUrl}/api/`,
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

axiosIns.interceptors.request.use(config => {
  Vue.prototype.$Progress.start()
  return config
}, error => {
  Vue.prototype.$Progress.fail()
  return Promise.reject(error)
})

axiosIns.interceptors.response.use(response => {
  Vue.prototype.$Progress.finish()
  return response
}, error => {
  Vue.prototype.$Progress.fail()
  return Promise.reject(error)
})

Vue.prototype.$http = axiosIns

export default axiosIns
